import type { ReactChild, ReactNode } from 'react';
import React from 'react';
import DOMPurify from 'dompurify';
import { cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { SECTION_MESSAGE_APPEARANCE_TYPES } from '../constants';
import type { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

const contentContainerStyles = cssMap({
	regular: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '95%',
	},
	isCard: { maxWidth: '100%' },
});

const sectionMessageTitleStyles = cssMap({
	regular: {
		font: token('font.heading.small'),
		marginTop: 0,
		color: token('color.text'),
	},
	inlineModal: {
		font: token('font.heading.medium'),
		marginTop: token('space.0'),
		paddingBottom: token('space.200'),
	},
});

const sectionMessageBodyStyles = cssMap({
	withTitle: { marginTop: token('space.100') },
	noTitle: { marginTop: '0' },
});

const sectionMessageHeaderButtonStyles = cssMap({
	regular: {
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'absolute',
		top: token('space.200'),
		right: token('space.300'),
	},
	default: {
		top: token('space.400'),
		right: token('space.600'),
	},
	isCardDefault: {
		right: token('space.400'),
	},
	isCard: {
		right: token('space.200'),
	},
});

type ButtonGroupAlignment = 'left' | 'right';

const sectionMessageActionRowStyles = cssMap({
	default: {
		display: 'flex',
		marginTop: token('space.200'),
	},
	right: {
		justifyContent: 'flex-end',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'> *': {
			flexFlow: 'row-reverse',
		},
	},
	inlineModal: {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginTop: '96px',
		display: 'flex',
	},
});

const sectionMessageFooterStyles = cssMap({
	default: {
		marginTop: token('space.200'),
		marginBottom: token('space.200'),
		paddingTop: token('space.150'),
		borderTop: `2px solid ${token('color.border')}`,
	},
});

const sectionMessageContainerStyles = cssMap({
	regular: {
		backgroundColor: token('elevation.surface.overlay'),
		borderRadius: '3px',
		display: 'flex',
		flexGrow: 1,
		margin: '0 auto',
	},
	withTasteAndToneStyling: {
		backgroundColor: 'transparent',
		margin: '0',
	},
	default: {
		padding: `${token('space.200')} ${token('space.300')}`,
		boxShadow: token('elevation.shadow.overlay'),
		maxWidth: '710px',
	},
	flat: {
		padding: `${token('space.200')} ${token('space.300')}`,
		maxWidth: '710px',
	},
	inline: {
		font: token('font.body'),
	},
	inlineModal: {
		maxWidth: '400px',
	},
});

export type EditionSectionMessageProps = {
	actions: ReactNode;
	appearanceType?: SECTION_MESSAGE_APPEARANCE_TYPES;
	body: ReactNode;
	edition?: ConfluenceEdition;
	footer?: ReactChild | null;
	title?: ReactChild;
	headerButton?: ReactChild;
	withTasteAndToneStyling?: boolean;
	buttonGroupAlignment?: ButtonGroupAlignment;
};

/**
 * @deprecated Please reach out to #cc-virality for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const EditionSectionMessage = ({
	actions,
	appearanceType,
	body,
	footer,
	title,
	headerButton,
	withTasteAndToneStyling = false,
	buttonGroupAlignment = 'left',
}: EditionSectionMessageProps) => {
	const withTitle = Boolean(title);
	const isCard = Boolean(buttonGroupAlignment === 'right');

	const updatedAppearanceType =
		appearanceType ||
		(withTasteAndToneStyling
			? SECTION_MESSAGE_APPEARANCE_TYPES.INLINE
			: SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT);

	return (
		<div
			css={[
				sectionMessageContainerStyles['regular'],
				withTasteAndToneStyling && sectionMessageContainerStyles['withTasteAndToneStyling'],
				updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
					sectionMessageContainerStyles['default'],
				updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.FLAT &&
					sectionMessageContainerStyles['flat'],
				updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE &&
					sectionMessageContainerStyles['inline'],
				updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
					sectionMessageContainerStyles['inlineModal'],
			]}
			data-testid="edition-section-message"
		>
			<div css={[contentContainerStyles['regular'], isCard && contentContainerStyles['isCard']]}>
				{withTitle && (
					<div
						css={[
							sectionMessageTitleStyles['regular'],
							updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
								sectionMessageTitleStyles['inlineModal'],
						]}
					>
						{title}
					</div>
				)}
				{typeof body === 'string' ? (
					<div
						css={[
							sectionMessageBodyStyles['noTitle'],
							withTitle && sectionMessageBodyStyles['withTitle'],
						]}
						// See https://hello.atlassian.net/browse/APOLLO-3511
						// and other code tagged with this ticket
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
					/>
				) : (
					<div
						css={[
							sectionMessageBodyStyles['noTitle'],
							withTitle && sectionMessageBodyStyles['withTitle'],
						]}
					>
						{body}
					</div>
				)}
				{headerButton && (
					<div
						css={[
							sectionMessageHeaderButtonStyles['regular'],
							updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
								sectionMessageHeaderButtonStyles['default'],
							isCard && sectionMessageHeaderButtonStyles['isCard'],
							isCard &&
								updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
								sectionMessageHeaderButtonStyles['isCardDefault'],
						]}
					>
						{headerButton}
					</div>
				)}
				{actions && (
					<div
						css={[
							sectionMessageActionRowStyles['default'],
							buttonGroupAlignment === 'right' && sectionMessageActionRowStyles['right'],
							updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
								sectionMessageActionRowStyles['inlineModal'],
						]}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="actionButtons"
					>
						{actions}
					</div>
				)}
				{footer && <div css={sectionMessageFooterStyles['default']}>{footer}</div>}
			</div>
		</div>
	);
};
