import isFunction from 'lodash/isFunction';
import type { FC, PropsWithChildren, ReactChild, ReactElement } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import type { Appearance } from '@atlaskit/button/types';
import { Box, xcss } from '@atlaskit/primitives';

import {
	UPSELL_EDITION_UPFLOW_EXPERIENCE,
	UPSELL_EDITION_PREMIUM_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { UpFlowComponentFlowType } from '@confluence/growth-experiment-helpers';
import { useSessionData } from '@confluence/session-data';
import { InProductHelpButton } from '@confluence/in-product-help';

import { LEARN_MORE_LINK, UP_FLOW_EP_MESSAGE_ID } from '../constants';
import type { WithIsSiteAdminProps } from '../higher-order';
import { withIsSiteAdmin } from '../higher-order';
import { useEditionsAnalyticsContext } from '../Upgrade/AnalyticsProvider';

import { ConfluenceEdition } from './__types__/waitForEditionChangeQuery';
import { UpFlowCommerceRouterLoadable } from './UpFlowCommerceRouterLoadable';
import { calculateTestId } from './calculateTestId';

export type ButtonAppearanceType = Appearance | 'inline-link';

export type LearnMoreButtonProps = React.PropsWithChildren<{
	analyticsProps: {
		actionSubjectId?: string;
		attributes?: {
			isGrandfathered?: boolean;
			flagKey?: string;
		};
		source: string;
	};
	appearance: ButtonAppearanceType;
	targetEdition?: ConfluenceEdition;
	onUpFlowOpen?: Function;
	isGrandfathered?: boolean;
	inProductHelpId?: string;
	inProductHelpTopic?: string;
	href?: string;
	icon?: ReactChild;
	onClick?(): void;
	ariaLabel?: MessageDescriptor;
}>;

type LearnMoreButtonWithUpFlowProps = PropsWithChildren<
	LearnMoreButtonProps & WithIsSiteAdminProps
>;

const inProductHelpButtonWithPadding = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	margin: 'auto',
});

const inProductHelpButtonWithoutPadding = xcss({
	padding: 'space.0',
});

export const i18n = defineMessages({
	tryItFree: {
		id: 'change-edition.section.message.try.free',
		defaultMessage: 'Try it free',
		description: 'The text of the link which starts the user onto the path of free trial',
	},
	learnMoreButton: {
		id: 'change-edition.section.message.general.button.link',
		defaultMessage: 'Learn more',
		description: 'A button that links to action description help page',
	},
});

export const LearnMoreButtonComponent: FC<LearnMoreButtonWithUpFlowProps> = ({
	analyticsProps,
	appearance,
	isSiteAdmin,
	onUpFlowOpen,
	targetEdition = ConfluenceEdition.STANDARD,
	isGrandfathered = false,
	inProductHelpId,
	inProductHelpTopic,
	href,
	children,
	icon,
	onClick,
	ariaLabel,
	...learnMoreProps
}: LearnMoreButtonWithUpFlowProps) => {
	const intl = useIntl();
	const { cloudId, edition, userId, isLicensed } = useSessionData();

	const { createAnalyticsEvent } = useEditionsAnalyticsContext();

	const [isUpFlowOpen, setIsUpFlowOpen] = useState(false);

	const experienceTracker = useContext(ExperienceTrackerContext);

	const showUpFlow = isLicensed && (!isSiteAdmin || edition === ConfluenceEdition.FREE);

	const { source, actionSubjectId } = analyticsProps;

	const testId = calculateTestId({ source, actionSubjectId });

	const message =
		children !== undefined ? children : <FormattedMessage {...i18n.learnMoreButton} />;

	const iconOnly = icon && !message;

	useEffect(
		() => () => {
			if (!showUpFlow) {
				experienceTracker.abort({
					name: UPSELL_EDITION_PREMIUM_EXPERIENCE,
					reason: 'Canceled by user',
				});
			}
		},
		[experienceTracker, showUpFlow],
	);

	const fireClickAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'freeLearnMore',
				...analyticsProps,
				attributes: {
					currentEdition: edition,
					isSiteAdmin,
					linkMessage: i18n.learnMoreButton.defaultMessage,
					targetEdition,
					...analyticsProps.attributes,
				},
			},
		}).fire();

		experienceTracker.start({
			name: showUpFlow ? UPSELL_EDITION_UPFLOW_EXPERIENCE : UPSELL_EDITION_PREMIUM_EXPERIENCE,
			attributes: {
				currentEdition: edition,
				linkClicked: actionSubjectId || 'freeLearnMore',
				source,
				targetEdition,
			},
		});
	};

	const onUpFlowClose = () => {
		if (!isFunction(onUpFlowOpen)) {
			// only attempt to close if there is no onUpFlowOpen handler (as
			// UpFlowComponent will invoke it to unmount our parent already)
			setIsUpFlowOpen(false);
		}
	};

	const renderLearnMoreButton = (props = {}) => {
		if (appearance === 'inline-link') {
			return <a {...props}>{message}</a>;
		} else {
			return (
				<Button
					appearance={appearance}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={appearance === 'subtle-link' ? undefined : 'primaryButton'}
					id="learnMoreLink"
					spacing={iconOnly ? 'none' : 'default'}
					iconBefore={icon}
					testId={testId}
					{...props}
				>
					{message}
				</Button>
			);
		}
	};

	const fireAnalyticsEvent = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'helpLink',
				source: 'adminKeyUpSell',
				attributes: {
					targetArticleId: inProductHelpId,
					isSPA: true,
				},
			},
		}).fire();
	};

	if (isGrandfathered && !isSiteAdmin) {
		return null;
	}

	// prioritizing href if it passed by the consumer regardless of edition type
	if (!href) href = showUpFlow ? '#' : LEARN_MORE_LINK;
	const target = href.startsWith('#') ? undefined : '_blank';

	const buttonProps = {
		target,
		href,
		onClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
			fireClickAnalytics();
			if (onClick) {
				onClick();
				return;
			}

			if (!target && showUpFlow) {
				setIsUpFlowOpen(true);
				ev.preventDefault();
			}
		},
		'aria-label': ariaLabel && intl.formatMessage(ariaLabel),
		testId,
		...learnMoreProps,
	};

	return (
		<>
			{!showUpFlow && inProductHelpId && inProductHelpTopic ? (
				/**
				 * Special handling In Product Help Button
				 * InProductHelpButton force the spacing to none, thus creating a BOX here to add back the padding and margin
				 */
				<Box xcss={iconOnly ? inProductHelpButtonWithoutPadding : inProductHelpButtonWithPadding}>
					<InProductHelpButton
						articleId={inProductHelpId}
						onClick={fireAnalyticsEvent}
						articleTopic={inProductHelpTopic}
						testId={testId}
						{...learnMoreProps}
					>
						{iconOnly ? (icon as ReactElement) : (message as ReactElement)}
					</InProductHelpButton>
				</Box>
			) : (
				renderLearnMoreButton(buttonProps)
			)}
			{showUpFlow && isUpFlowOpen && (
				<UpFlowCommerceRouterLoadable
					cloudId={cloudId}
					atlassianAccountId={userId || undefined}
					canChangeEdition={isSiteAdmin}
					currentEdition={edition || undefined}
					targetEdition={targetEdition}
					flow={UpFlowComponentFlowType.LEARN_MORE}
					product="confluence"
					touchpointId={source}
					onClose={onUpFlowClose}
					onOpen={onUpFlowOpen}
					isExperienceTrackerEnabled
					epMessageId={UP_FLOW_EP_MESSAGE_ID}
				/>
			)}
		</>
	);
};

/**
 * @deprecated Please reach out to #cc-virality for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const LearnMoreButton = withIsSiteAdmin<LearnMoreButtonProps>(LearnMoreButtonComponent);
