import { LoadableAfterPaint } from '@confluence/loadable';

export { HELP_PANEL_WIDTH, HELP_PANEL_ID, HELP_PANEL_VERSION } from './constants';

export { getRouteData } from './inProductHelpUtil';

export {
	InProductHelpProvider,
	InProductHelpProviderContainer,
	useInProductHelpActions,
	useInProductHelpArticleId,
	useInProductHelpRouteGroup,
	useInProductHelpRouteName,
} from './InProductHelpProvider';

export { HelpPanelQuery } from './HelpPanelQuery';

export const HelpPanel = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-HelpPanel" */ './HelpPanel')).HelpPanel,
});

export const InProductHelpButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ButtonsInProductHelpButton" */ './Buttons/InProductHelpButton'
			)
		).InProductHelpButton,
});

export const BackupManagerButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ButtonsBackupManagerButton" */ './Buttons/BackupManagerButton'
			)
		).BackupManagerButton,
});

export const SiteSettingsHelpPanelLink = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ButtonsSiteSettingsHelpPanelLink" */ './Buttons/SiteSettingsHelpPanelLink'
			)
		).SiteSettingsHelpPanelLink,
});
